<template>
  <VxTable
    class="ledger-table"
    :loading="loading"
    :items="items"
    :headers="headers"
    fixed-header
    striped
  >
    <template #item="{ item, index }">
      <template v-if="item.subheaderKey">
        <VxTableRow :key="index">
          <VxTableHeaderCell
            class="text-uppercase text-body-1 font-weight-bold"
            type="text"
            colspan="3"
          >
            {{ $t(item.subheaderKey) }}
          </VxTableHeaderCell>
        </VxTableRow>
      </template>
      <template v-else>
        <VxTableRow :key="index" class="indent-row">
          <VxTableHeaderCell
            v-if="item.total"
            class="text-uppercase"
            type="text"
          >
            {{ $t(item.descriptionKey) }}
          </VxTableHeaderCell>
          <VxTableDataCell v-else type="text" class="description text-body-2">
            {{ $t(item.descriptionKey) }}
          </VxTableDataCell>
          <VxTableDataCell type="currency" class="debit">
            {{ item.debit }}
          </VxTableDataCell>
          <VxTableDataCell type="currency" class="credit">
            {{ item.credit }}
          </VxTableDataCell>
        </VxTableRow>
      </template>
    </template>
  </VxTable>
</template>
<script>
import {
  VxTable,
  VxTableRow,
  VxTableDataCell,
  VxTableHeaderCell,
} from "@/core-ui";

export default {
  name: "LedgerTable",
  components: { VxTable, VxTableRow, VxTableDataCell, VxTableHeaderCell },
  props: {
    items: {
      type: Array,
      required: true,
      // ex:
      // [
      //   {
      //     subheaderKey: 'i18n.path.key'
      //   },
      //   {
      //     descriptionKey: 'i18n.other.path.key',
      //     credit: 224.5
      //   }
      // ]
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    headers: [
      {
        title: "Description",
        field: "description",
        type: "text",
      },
      {
        title: "Debit",
        field: "debit",
        type: "currency",
      },
      {
        title: "Credit",
        field: "credit",
        type: "currency",
      },
    ],
  }),
};
</script>

<style lang="scss">
.ledger-table table {
  border: thin solid rgba(0, 0, 0, 0.12);

  tr.indent-row td.vx-table__data-cell:first-of-type,
  tr.indent-row th.vx-table__header-cell:first-of-type {
    padding-left: 2rem !important;
  }

  tr td.debit,
  tr td.credit {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }
}
</style>
