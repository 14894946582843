<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="6" lg="5" xl="4">
        <v-icon size="30" color="ui darken-3" class="mb-3 mr-2"
          >$raiDaybook</v-icon
        >
        <span class="text-h5">Ledger</span>
        <LedgerTable :loading="ledgerLoading" :items="parsedLedgerItems" />
      </v-col>
      <v-col cols="12" md="6" lg="5" xl="4">
        <v-icon size="30" color="ui darken-3" class="mb-3 mr-2">$edit</v-icon>
        <span class="text-h5">Journal</span>
        <LedgerTable :loading="journalLoading" :items="parsedJournalItems" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LedgerTable from "./components/LedgerTable";
import { hasRouteDay } from "@/mixins/routerParams.js";
import { LEDGER_QUERY, JOURNAL_QUERY } from "./graphql";
import { LedgerViewMap, JournalViewMap } from "../constants";

export function parseType(type, val) {
  if (type == "dual_db") return val > 0 ? "debit" : "credit"; // dual_db - debit if pos, credit if neg
  if (type == "dual_cr") return val < 0 ? "debit" : "credit"; // dual_cr - credit if pos, debit if neg
  return type;
}

export function parseItems(items, viewMap) {
  const tableItems = Object.entries(viewMap).reduce(
    (aggregate, [itemKey, subItems]) => {
      return [
        ...aggregate,
        // section header
        { subheaderKey: `report.app.ledgerAndJournal.subheaders.${itemKey}` },

        // items need to be mapped into an array of arrays and then reduced to an array
        ...subItems
          .map((subItem) => {
            let tableItem = {
              descriptionKey: `report.app.ledgerAndJournal.${subItem.name}`,
              total: !!subItem.total,
            };
            if (subItem.type == "both") {
              tableItem["debit"] = items[`${subItem.name}Debit`];
              tableItem["credit"] = items[`${subItem.name}Credit`];
            } else {
              const subItemVal = items[subItem.name];
              if (!subItemVal) return []; // dont add if 0
              tableItem[parseType(subItem.type, subItemVal)] =
                Math.abs(subItemVal);
            }

            // add padding if specified
            if (subItem.padTop) return [{ subheaderKey: "" }, tableItem];

            return [tableItem];
          })
          .reduce((agg, val) => {
            return [...agg, ...val];
          }, []),
      ];
    },
    []
  );

  return tableItems;
}

export default {
  name: "LedgerAndJournalView",
  components: { LedgerTable },
  props: {
    storeId: {
      type: [Number, String],
      required: true,
    },
    displayDate: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    ledgerLoading: false,
    journalLoading: false,
  }),
  apollo: {
    ledgerReport: {
      query: LEDGER_QUERY,
      variables() {
        return {
          storeId: this.storeId,
          businessDate: this.dayDate,
        };
      },
      watchLoading(isLoading) {
        this.ledgerLoading = isLoading;
      },
      skip() {
        return !this.storeId || !this.displayDate;
      },
      update(data) {
        const { ledgerReport } = data;
        return ledgerReport;
      },
    },
    journalReport: {
      query: JOURNAL_QUERY,
      variables() {
        return {
          storeId: this.storeId,
          businessDate: this.dayDate,
        };
      },
      watchLoading(isLoading) {
        this.journalLoading = isLoading;
      },
      skip() {
        return !this.storeId || !this.displayDate;
      },
      update(data) {
        const { journalReport } = data;
        return journalReport;
      },
    },
  },
  computed: {
    parsedLedgerItems() {
      if (!this.ledgerReport) return [];

      return parseItems(this.ledgerReport, LedgerViewMap);
    },
    parsedJournalItems() {
      if (!this.journalReport) return [];
      return parseItems(this.journalReport, JournalViewMap);
    },
    dayDate() {
      return new Date(this.displayDate);
    },
  },
};
</script>
